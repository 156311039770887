import React from 'react';

// NOTE: Need to re-enable for future growler db
//import { collection, getDocs, orderBy, query } from 'firebase/firestore';
//import { db } from '../../firebase';

export const BrewerySignup = () => {

  return (
     <div className="blog-container">
      {/* Header Section */}
      <div className="blog-header">
        <div className="container">
          <h1>Brewery Sign-Up!</h1>
          <p>Excited to add you to our directory for beer enthusiasts.</p>
        </div>
      </div>

      {/* Signup Section */}
      <div className="container">
       <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSesW3AUIbY2GX_ci1dZr10P828vbbSzQF44MDI7lm2Z-4ab2w/viewform?embedded=true" width="640" height="932" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
    </div>
  </div>
  );
};
