import React, { useEffect } from 'react';
import { styles } from './styles';

const BuyMeCoffeeButton = () => {
  useEffect(() => {

    // Create and add new script
    const script = document.createElement('script');
    script.src = 'https://cdnjs.buymeacoffee.com/1.0.0/button.prod.min.js';
    script.async = true;
    script.dataset.name = 'bmc-button';
    script.dataset.slug = 'growlerguru';
    script.dataset.color = '#5F7FFF';
    script.dataset.emoji = '🍺';
    script.dataset.font = 'Cookie';
    script.dataset.text = 'Buy me a beer';
    script.dataset.outlineColor = '#000000';
    script.dataset.fontColor = '#ffffff';
    script.dataset.coffeeColor = '#FFDD00';

    // Add custom styling to override BMC widget styles
    const styleElement = document.createElement('style');
    styleElement.textContent = `
      .bmc-btn {
        min-width: unset !important;
        width: auto !important;
        height: auto !important;
        padding: 0.5rem 1rem !important;
        transform: scale(0.75) !important;
      }
      @media (max-width: 768px) {
        .bmc-btn {
          transform: scale(0.65) !important;
        }
      }
      @media (max-width: 480px) {
        .bmc-btn {
          transform: scale(0.55) !important;
        }
      }
    `;
    document.head.appendChild(styleElement);
    document.body.appendChild(script);
  }, []);

  return (
    <div className={styles.container}>
      <a 
        href="https://www.buymeacoffee.com/growlerguru" 
        target="_blank" 
        rel="noopener noreferrer"
        className={styles.fallbackButton}
      >
        <img 
          style={{ height: "50px" }}
          src="https://cdn.buymeacoffee.com/buttons/v2/default-blue.png" 
          alt="Buy Me A Beer" 
          className={styles.fallbackImage}
        />
      </a>
    </div>
  );
};

export default BuyMeCoffeeButton;
