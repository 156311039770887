export const styles = {
  container: `
    flex items-center justify-center
    my-2
    mx-auto
    w-[50%] max-w-[300px] min-w-[120px]
    h-auto
    relative
    sm:w-[70%] lg:w-[30%]
  `,
  fallbackButton: `
    inline-flex items-center justify-center
    w-full h-full
  `,
  fallbackImage: `
    w-full h-full
    object-contain
  `
};

