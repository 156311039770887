import React from "react";
import { BuyMeCoffeeButton } from "./buttons";


export const Team = (props) => {
  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Meet the Team</h2>
          <p>
	  We're your friendly neighborhood beer enthusiasts who believe that great brews, wagging tails, and game nights are the perfect recipe for happiness – and we can't wait to share a pint (or a growler) with you!
          </p>
        </div>
        <div id="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-6 col-sm-12 team">
                  <div className="thumbnail">
                    {" "}
                    <img src={d.img} alt="..." className="team-img" />
                    <div className="caption">
                      <h4>{d.name}</h4>
                      <p>{d.job}</p>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
        <div className="container">
            <h3>Support Our Team</h3>
            <p>
            Love what we're doing? Help us keep the project alive by buying us a coffee (or a beer)!
            Your support goes toward hosting fees and our mission to explore and share the best growler-filling breweries around. Every contribution helps—cheers to you! 🍻
            </p>
            <br/>
            <div className="flex justify-center items-center">
              <BuyMeCoffeeButton />
            </div>
       </div>
    </div>
  );
};
