import React, { useState }  from "react";
import { db } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';
import { BrewerySignUpButton } from "./buttons";

export const Services = (props) => {
  const [email, setEmail] = useState('');
  const [zipcode, setZipcode] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // 2. Add user to waitlist in Firestore
      await addDoc(collection(db, 'waitlist'), {
        email: email,
	zipcode: zipcode,
        timestamp: new Date(),
      });

      // 3. Success handling
      console.log('User added to waitlist!');
      setEmail('');
      setZipcode('');
      alert('Thank you for joining the waitlist!');
    } catch (error) {
      // 4. Error handling
      console.error('Error adding user to waitlist: ', error);
    }
  };

  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Waiting List</h2>
	  <p>Never wonder 'Where can I fill my growler?'.</p>
          <p>Get on the list: Be a <strong>VIP</strong> when we launch!</p>
        </div>
	<form name="waitList" validate onSubmit={handleSubmit}>
        <div className="row">
	  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="zipcode"
                        name="zipcode"
	  		value={zipcode}
                        className="form-control"
                        placeholder="Enter your zipcode (optional)"
                        onChange={(e) => setZipcode(e.target.value)}
                      />
                      <p className="help-block"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
	  		value={email}
                        className="form-control"
                        placeholder="Enter your email"
                        required
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
        </div>
		<div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Sign up
                </button>
              </form>
      </div>
      <br/><br/><br/><br/>
            <h2>Breweries!</h2>
            <p>Do you fill growlers?</p>
            <p>Can we add your growler filling taproom to our list?</p>
            <br/>
              <div className="flex justify-center items-center">
                <BrewerySignUpButton />
              </div> 
    </div>
  );
};
