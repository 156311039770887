import React from 'react';
import { useNavigate } from 'react-router-dom';


const BrewerySignupButton = () => {
  const navigate = useNavigate();

  const handleBrewerySignup = () => {
    // Navigate to the brewery signup page
    navigate('/brewery/signup');
  };

  return (
    <button 
      onClick={handleBrewerySignup}
      variant="default"
      className="btn btn-custom btn-lg"
    >
      Brewery Sign Up
    </button>
  );
};

export default BrewerySignupButton;

