import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
	apiKey: "AIzaSyCFFI2QqDfjftXOnSfDw0GLdzbcQgHh_ts",
  	authDomain: "growlerguru-378de.firebaseapp.com",
  	projectId: "growlerguru-378de",
  	storageBucket: "growlerguru-378de.appspot.com",
  	messagingSenderId: "936232591909",
  	appId: "1:936232591909:web:da255f016b9b358d1b95cc",
  	measurementId: "G-D0DR99MS4Y"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firestore
export const db = getFirestore(app);
